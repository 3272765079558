import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://ecgraph.maxxchain.org/subgraphs/name/maxxswap/exchange',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://ecgraphindex.maxxchain.org/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://ecgraph.maxxchain.org/subgraphs/name/maxxswap/blocks',
  }),
  cache: new InMemoryCache(),
})

dayjs.extend(utc)

export const startTimestamp = () => {
  return dayjs.unix(Math.round(new Date().getTime() / 1000).toString())
}

export const startPriceTimestamp = () => {
  return dayjs.unix(1689865579)
}
